<!-- 乡贤管理 -->
<template>
    <div>
<!--        <headtitle Otitle="登记管理" />-->
        <el-card class="box-card">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item>登记管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="hr-20"></div>
            <el-button class="fl" type="primary" size="mini" @click="requestAdd">登记</el-button>

            <el-table :data="lineTable" stripe style="width: 100%">

                <el-table-column prop="summary" label="年底摸底汇总表" align="center"/>

                <el-table-column prop="data" label="创建时间" width="180" align="center"/>

                <el-table-column prop="editData" label="上次修改时间" align="center"/>

                <el-table-column prop="status" label="状态" align="center">
                    <el-button size="mini" type="warning">提交申请中</el-button>
                </el-table-column>

                <el-table-column prop="operation" label="操作" align="center">
                    <el-button type="primary" size="mini">上传文件</el-button>
                </el-table-column>
            </el-table>

            <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" v-model="pages"
                           @current-change="requestPage" id="pagination"/>
        </el-card>
    </div>
</template>

<script>

    //import headtitle from "../../../components/ControlPanel/headtitle";

    export default {
        components: {
            //headtitle
        },
        data() {
            return {
                page: 1,
                pages: 0,
                pageSize: 10,
                total: 1,
                lineTable: [
                    { summary: '赣州经开发在外乡贤摸底汇总表',data: '2020-6-30',editData: '2020-7-1', status: '提交申请中', operation: '提交申请' },
                    { summary: '赣州经开发在外乡贤摸底汇总表',data: '2020-6-30',editData: '2020-7-1', status: '提交申请中', operation: '提交申请' },
                    { summary: '赣州经开发在外乡贤摸底汇总表',data: '2020-6-30',editData: '2020-7-1', status: '提交申请中', operation: '提交申请' },
                    { summary: '赣州经开发在外乡贤摸底汇总表',data: '2020-6-30',editData: '2020-7-1', status: '提交申请中', operation: '提交申请' },
                ],
            }
        },
        methods: {
          request() {
            let newPage = this.$router.resolve({
              name: 'EntryStoma',
            })
            window.open(newPage.href, '_blank')
          },
            requestAdd() {
                this.$router.push({path: '/registration'})
            },
            //  分页
            requestPage() {

            },
        }
    }
</script>

<style lang="less" scoped>
    #pagination {
        margin: 32px 0 16px 0 ;
    }
</style>